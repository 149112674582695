/* eslint-disable react/prop-types */
import {
	Drawer,
	Box,
	Toolbar,
	AppBar,
	Button,
	List,
	Link,
	ListItem,
	Typography,
} from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import myImage from './logo-orange.png';
import { LanguageMenu } from './LanguageMenu';

export const LeftSideDrawer = ({ lan, setLan, mediumWidth, smallWidth }) => {
	const [open, setOpen] = React.useState(false);

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	const links = [
		{
			text: 'Acasă',
			textEn: 'Home',
			to: '#home',
		},

		{
			text: 'Servicii',
			textEn: 'Services',
			to: '#services',
		},
		{
			text: 'Contact',
			textEn: 'Contact',
			to: '#contact',
		},
		{
			text: 'Album',
			textEn: 'Album',
			to: '#photos',
		},
	];

	return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar
					position='fixed'
					sx={{ background: '#ffffff90', display: 'flex', p: 2 }}
				>
					<Toolbar sx={{ justifyContent: 'space-between' }}>
						<Button
							variant='contained'
							color='info'
							onClick={toggleDrawer(true)}
						>
							<MenuIcon sx={{ fill: '#ffffff' }} />
						</Button>
						<Box>
							<img
								src={myImage}
								style={{ maxHeight: '5vh', right: 0, marginLeft: 10 }}
								alt='logo'
							/>
						</Box>
					</Toolbar>
				</AppBar>
			</Box>
			<Drawer open={open} onClose={toggleDrawer(false)}>
				<Box sx={{ width: '150px', paddingLeft: '5vh', paddingTop: '5vh' }}>
					<List>
						{links.map((link) => (
							<ListItem key={link.text}>
								<Link
									href={link.to}
									onClick={toggleDrawer(false)}
									sx={{ textDecorationColor: '#f47842' }}
								>
									<Typography color='info.main'>
										{lan === 'Ro' ? link.text : link.textEn}
									</Typography>
								</Link>
							</ListItem>
						))}
						<br />
						<LanguageMenu
							lan={lan}
							setLan={setLan}
							mediumWidth={mediumWidth}
							smallWidth={smallWidth}
						/>
					</List>
				</Box>
			</Drawer>
		</>
	);
};
