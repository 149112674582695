/* eslint-disable react/prop-types */
import { AppBar, Link, Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import myImage from './logo-orange.png';
import React from 'react';
import { LanguageMenu } from './LanguageMenu';

export const Navbar = ({ lan, setLan, mediumWidth }) => {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position='fixed'
				sx={{ background: '#ffffff90', display: 'flex', p: 2 }}
			>
				<Toolbar sx={{ justifyContent: 'space-between' }}>
					<Box>
						<Link href='#home'>
							<Button
								variant='contained'
								color='info'
								sx={{ marginRight: 2, mt: mediumWidth && 1 }}
							>
								<Typography color='primary.light'>
									{lan === 'Ro' ? 'Acasă' : 'Home'}
								</Typography>
							</Button>
						</Link>
						<Link href='#services'>
							<Button
								variant='contained'
								color='info'
								sx={{ marginRight: 2, mt: mediumWidth && 1 }}
							>
								<Typography color='primary.light'>
									{lan === 'Ro' ? 'Servicii' : 'Services'}
								</Typography>
							</Button>
						</Link>
						<Link href='#contact'>
							<Button
								variant='contained'
								color='info'
								sx={{ marginRight: 2, mt: mediumWidth && 1 }}
							>
								<Typography color='primary.light'>Contact</Typography>
							</Button>
						</Link>
						<Link href='#photos'>
							<Button
								variant='contained'
								color='info'
								sx={{ marginRight: 2, mt: mediumWidth && 1 }}
							>
								<Typography color='primary.light'>Album</Typography>
							</Button>
						</Link>
						<LanguageMenu
							lan={lan}
							setLan={setLan}
							mediumWidth={mediumWidth}
							smallWidth={false}
						/>
					</Box>
					<Box>
						<img
							src={myImage}
							style={{ maxHeight: '9vh', right: 0, marginLeft: 10 }}
							alt='logo'
						/>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
};
