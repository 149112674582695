/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from '@mui/material';
import myLogo from './logo-orange.png';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';

function Copyright() {
	return (
		<Typography variant='body' color='#eeeeee'>
			{'Copyright © '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

export const Footer = ({ lan }) => {
	return (
		<>
			<Box
				component='footer'
				sx={{
					py: 5,
					px: 2,
					mt: 'auto',
					backgroundColor: '#eeeeee',
				}}
			>
				<Container maxWidth={'xl'}>
					<Grid container spacing={6}>
						<Grid item xs={12} sm={4}>
							<Typography variant='body1' mb={1}>
								{lan === 'Ro'
									? 'Asistent medical generalist licențiat '
									: 'Licensed general nurse'}
							</Typography>
							<Box display={'flex'} mb={1}>
								<CallIcon
									style={{
										fill: '#3D3D3D',
										marginRight: '5px',
										height: '30px',
										width: '30px',
									}}
								/>
								<Typography variant='body1'>0741 121 649</Typography>
							</Box>
							<Box display={'flex'}>
								<EmailIcon
									style={{
										fill: '#3D3D3D',
										marginRight: '5px',
										height: '30px',
										width: '30px',
									}}
								/>
								<Typography variant='body1' sx={{ wordBreak: 'break-all' }}>
									cccabinetindependentamg@gmail.com
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Typography variant='body1' mb={1}>
								{lan === 'Ro'
									? 'Servicii medicale la domiciliu sau la cabinet'
									: 'Medical services at home or at our office'}
							</Typography>
							<Typography variant='body1' mb={1}>
								Str. Crișan Nr. 82C, Dr. Turnu Severin
							</Typography>
							<Box display={'flex'}>
								<FacebookIcon
									style={{
										fill: '#3D3D3D',
										marginRight: '5px',
										height: '30px',
										width: '30px',
									}}
								/>
								<Link
									sx={{ cursor: 'pointer' }}
									color={'primary.main'}
									variant='body1'
									href={
										'https://www.facebook.com/profile.php?id=61560839865789'
									}
									target={'_blank'}
								>
									{lan === 'Ro'
										? 'Click aici pentru a afla mai multe'
										: 'Click here to find more'}
								</Link>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Box display={'flex'}>
								<img
									src={myLogo}
									style={{ maxHeight: '9vh', marginRight: '10px' }}
									alt='logo'
								/>
								<Box display={'block'} mt={1}>
									<Typography variant='h5' color='#f47842'>
										{lan === 'Ro' ? 'Asistent medical' : 'Medical nurse'}
									</Typography>
									<Typography
										variant='h5'
										color='#f47842'
										sx={{ wordBreak: 'break-word' }}
									>
										Camelia Căpitănescu
									</Typography>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				component='footer'
				sx={{
					py: 3,
					px: 2,
					mt: 'auto',
					backgroundColor: '#3D3D3D',
					textAlign: 'center',
				}}
			>
				<Typography color={'#ffffff'} variant='h5'>
					{lan === 'Ro' ? 'Cabinet asistență medicală' : 'Medical care office'}
				</Typography>
				<Copyright />
			</Box>
		</>
	);
};
