import { createTheme } from '@mui/material/styles';

// Define your custom theme
const theme = createTheme({
	palette: {
		primary: {
			main: '#3D3D3D', // typing
			light: '#ffffff', //typing
		},
		secondary: {
			light: '#C1C1C1', //grey
			main: '#1FA6D4', // blue
			dark: '#1C92BB', // blue for typing
		},
		info: {
			main: '#f47842', //orange
		},
	},
	typography: {
		fontFamily: '"Roboto Mono", monospace',
		h1: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: '6rem',
			fontWeight: 900,
			color: '#1C92BB',
		},
		h2: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: '4rem',
			fontWeight: 900,
			color: '#1C92BB',
		},
		h3: {
			fontFamily: '"Open sans", serif',
			fontSize: '3.3rem',
			fontWeight: 400,
			color: '#3D3D3D',
		},
		h4: {
			fontFamily: '"Open sans", serif',
			fontSize: '2.3rem',
			fontWeight: 400,
			color: '#3D3D3D',
		},
		h5: {
			fontFamily: '"Open sans", serif',
			fontSize: '1.7rem',
			fontWeight: 400,
			color: '#3D3D3D',
		},
		body1: {
			fontFamily: '"Open Sans", serif',
			fontSize: '1rem',
			fontWeight: 400,
			color: '#3D3D3D',
		},
	},
});

export default theme;
