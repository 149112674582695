/* eslint-disable react/prop-types */
import { Typography, Container, Box, Link } from '@mui/material';
import { Map, Marker, ZoomControl } from 'pigeon-maps';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Facebook from '@mui/icons-material/Facebook';
import React, { useState } from 'react';

export const Contact = ({ lan }) => {
	const myStyle = {
		paddingTop: '6vh',
		paddingBottom: '6vh',
	};

	const coordinates = [44.6442608, 22.647326];
	const [zoom, setZoom] = useState(13);

	const handleOpenDefaultMaps = () => {
		if (navigator.userAgent.toLowerCase().includes('android') === true)
			window.open(`geo:${coordinates[0]},${coordinates[1]}`);
		if (/iPad|iPhone|iPod/.test(navigator.userAgent) === true)
			window.open(`maps://?q=${coordinates[0]},${coordinates[1]}`);
	};

	return (
		<section style={myStyle} id='contact'>
			<div
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					flexWrap: 'wrap',
					width: '100%',
					textAlign: 'center',
				}}
			>
				<div
					style={{
						marginTop: '5vh',
						padding: '5vh',
						textAlign: 'center',
					}}
				>
					<Typography variant='h3' color={'secondary.main'} fontWeight={900}>
						{lan === 'Ro' ? 'Informații de contact' : 'Contact information'}
					</Typography>
				</div>
				<Typography mb={2}>
					{lan === 'Ro'
						? `Vă încurajăm să ne contactați prin intermediul următoarelor mijloace
				de comunicare:`
						: `We encourage you to contact us through the following communication channels:`}
				</Typography>
				<Container
					component={'div'}
					sx={{
						maxWidth: '1500px !important',
						display: 'flex',
					}}
				>
					<Map
						height={415}
						animate={true}
						center={coordinates}
						twoFingerDrag={true}
						zoom={zoom}
						onBoundsChanged={({ zoom }) => {
							setZoom(zoom);
						}}
					>
						<Marker width={50} color={'#1FA6D4'} anchor={coordinates} />
						<ZoomControl />
					</Map>
					<Box
						component={'div'}
						height={250}
						width={600}
						sx={{
							backgroundColor: 'primary.main',
							border: '1px solid #7AC019',
							textAlign: 'left',
							display: 'block',
							py: 10,
							px: 4,
						}}
					>
						<Box
							display='flex'
							mb={5}
							onClick={() => window.open('tel:+40741121649')}
						>
							<LocalPhoneIcon sx={{ color: '#ffffff', mr: 2 }} />
							<Typography color='#ffffff'>+40 741 121 649</Typography>
						</Box>
						<Box
							display='flex'
							mb={5}
							sx={{ cursor: 'pointer', wordBreak: 'break-word' }}
							onClick={() =>
								window.open('mailto:cccabinetindependentamg@gmail.com')
							}
						>
							<EmailIcon sx={{ color: '#ffffff', mr: 2 }} />
							<Typography color='#ffffff' sx={{ wordBreak: 'break-all' }}>
								cccabinetindependentamg@gmail.com
							</Typography>
						</Box>
						<Box display='flex' mb={5} onClick={() => handleOpenDefaultMaps()}>
							<LocationOnIcon sx={{ color: '#ffffff', mr: 2 }} />
							<Box display={'block'}>
								<Typography color='#ffffff'>Nr. 82C, str. Crișan</Typography>
								<Typography color='#ffffff'>
									Drobeta Turnu Severin, România
								</Typography>
							</Box>
						</Box>
						<Link
							href={'https://www.facebook.com/profile.php?id=61560839865789'}
							target='_blank'
							display='flex'
							color={'#ffffff'}
						>
							<Facebook sx={{ color: '#ffffff', mr: 2 }} />
							<Typography color={'#ffffff'}>Facebook</Typography>
						</Link>
					</Box>
				</Container>
			</div>
		</section>
	);
};
