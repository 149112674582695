/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Card, CardMedia, Typography, Container } from '@mui/material';

import photo1 from '../images/1.jpeg';
import photo2 from '../images/2.jpeg';
import photo3 from '../images/3.jpeg';
import photo4 from '../images/4.jpeg';
import photo5 from '../images/5.jpeg';
import photo6 from '../images/6.jpeg';
import photo7 from '../images/7.jpeg';
import photo8 from '../images/8.jpeg';
import photo9 from '../images/9.jpeg';
import photo10 from '../images/10.jpeg';
import photo11 from '../images/11.jpeg';
import photo12 from '../images/12.jpeg';

const photos = [
	{ id: 1, url: photo1, title: 'Photo 1' },
	{ id: 2, url: photo2, title: 'Photo 2' },
	{ id: 3, url: photo3, title: 'Photo 3' },
	{ id: 4, url: photo4, title: 'Photo 4' },
	{ id: 5, url: photo5, title: 'Photo 5' },
	{ id: 6, url: photo6, title: 'Photo 6' },
	{ id: 7, url: photo7, title: 'Photo 7' },
	{ id: 8, url: photo8, title: 'Photo 8' },
	{ id: 9, url: photo9, title: 'Photo 9' },
	{ id: 10, url: photo10, title: 'Photo 10' },
	{ id: 11, url: photo11, title: 'Photo 11' },
	{ id: 12, url: photo12, title: 'Photo 12' },
];

export const Photos = ({ lan }) => {
	const myStyle = {
		paddingTop: '6vh',
		paddingBottom: '6vh',
	};

	return (
		<section style={myStyle} id='photos'>
			<div>
				<div
					style={{
						marginTop: '5vh',
						padding: '5vh',
						textAlign: 'center',
					}}
				>
					<Typography variant='h3' color={'secondary.main'} fontWeight={900}>
						{lan === 'Ro' ? 'Povestea noastră' : 'Our story'}
					</Typography>
				</div>
				<Container maxWidth='xl'>
					<Grid container spacing={3}>
						{photos.map((photo) => (
							<Grid item xs={12} sm={6} md={3} key={photo.id}>
								<Card>
									<CardMedia
										component='img'
										alt={photo.title}
										height='200'
										image={photo.url}
									/>
								</Card>
							</Grid>
						))}
					</Grid>
				</Container>
				<br />
			</div>
		</section>
	);
};
