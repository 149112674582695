import './App.css';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { Services } from './components/Services';
import { Home } from './components/Home';
import { Contact } from './components/Contact';
import { Photos } from './components/Photos';
import { LeftSideDrawer } from './components/LeftSideDrawer';
import React, { useLayoutEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import themeMedium from './themeMedium';
import themeSmall from './themeSmall';

function App() {
	const [lan, setLan] = useState('Ro');
	const [mediumWidth, setMediumWidth] = useState(false);
	const [smallWidth, setSmallWidth] = useState(false);

	useLayoutEffect(() => {
		function handleResize() {
			if (window.innerWidth < 1350 && window.innerWidth > 600) {
				setMediumWidth(true);
				setSmallWidth(false);
			} else if (window.innerWidth < 600) {
				setSmallWidth(true);
				setMediumWidth(false);
			} else {
				setSmallWidth(false);
				setMediumWidth(false);
			}
		}

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handleTheme = () => {
		if (mediumWidth === true) return themeMedium;
		else if (smallWidth === true) return themeSmall;
		else return theme;
	};

	return (
		<ThemeProvider theme={handleTheme()}>
			{!smallWidth && (
				<Navbar
					lan={lan}
					setLan={setLan}
					mediumWidth={mediumWidth}
					smallWidth={smallWidth}
				/>
			)}
			{smallWidth && (
				<LeftSideDrawer
					lan={lan}
					setLan={setLan}
					mediumWidth={false}
					smallWidth={true}
				/>
			)}
			<Home lan={lan} mediumWidth={mediumWidth} smallWidth={smallWidth} />
			<Services lan={lan} />
			<Contact lan={lan} />
			<Photos lan={lan} />
			<Footer lan={lan} />
		</ThemeProvider>
	);
}

export default App;
