/* eslint-disable react/prop-types */
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';

export const LanguageMenu = ({ lan, setLan, mediumWidth, smallWidth }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Button
				color={!smallWidth ? 'info' : '#ffffff'}
				variant={!smallWidth && 'contained'}
				id='basic-button'
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{ mt: mediumWidth && 1 }}
			>
				<LanguageIcon
					sx={{ fill: !smallWidth ? '#ffffff' : '#f47842', mr: 1 }}
				/>
				<Typography color={!smallWidth ? 'primary.light' : 'info.main'}>
					{lan}
				</Typography>
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
					sx: {
						padding: '8px',
					},
				}}
				sx={{ marginTop: 1 }}
			>
				<MenuItem
					onClick={() => {
						setLan('En');
						handleClose();
					}}
				>
					<Typography color='info.main'>English</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setLan('Ro');
						handleClose();
					}}
				>
					<Typography color='info.main'>Română</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};
